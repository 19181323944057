import { Component, OnInit } from '@angular/core';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { CausalService } from 'src/app/shared/services/causal.service';
import { SubcausalService } from 'src/app/shared/services/subcausal.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { DescargaFilter } from 'src/app/shared/pipes/download-filter.pipe';
import { GlobalService } from 'src/app/shared/services/global.service';
import { MotivoService } from 'src/app/shared/services/motivo.service';
import { DataSharingService } from 'src/app/data-sharing.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NgbdModalAlert } from 'src/app/app.component';
import { PriorityService } from 'src/app/shared/services/priority.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { differenceInDays } from 'date-fns';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
  providers: [DescargaFilter]
})
export class TicketsComponent implements OnInit {
  public tickets: any = [];

  private causals = [];
  private subcausals = [];
  private states = [];
  private users = [];
  private states_check = [];

  private newStatus: any;  // Model for the new status select
  private selectAll: boolean = false;  // Model for select/unselect all

  public isFiltersCollapsed = true;

  public ticketId= '';
  public nombreCliente= '';
  public apellidoCliente= '';
  public docCliente= '';
  public asuntoCausal= '';
  public asuntoSubcausal= '';
  public asuntoMotivo= '';
  public responsableNombre= '';
  public responsableApellido= '';
  public involucrados= '';
  public areas= '';
  public estado= '';
  public fdesde='';
  public fhasta='';
  public fcrdesde='';
  public fcrhasta='';
  public motivos = [];
  public noCerrados = false;

  public repdesde='';
  public rephasta='';

  public listaid= [];

  public pagina= 0;

  public listaFiltros= [];
  public selectFiltro= 0;
  public listaColumnas= {};
  public columnas= '';
  public excel= '';
  public fechasReporte= {};
  public fechasr = '';
  public numero_llamadas = '';
  public dias_desde_creacion = '';

  public busquedafiltros={
    pagina:null,
    filtro:null,
    columnas:null,
    nocerrados:'',
  };


  public priorities = [];

  public wysiwyg_config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '10rem',
    maxHeight: '15rem',
    placeholder: 'Escriba su comentario aquí',
    sanitize: false,
    toolbarPosition: 'top',
    defaultFontName: 'Arial',
    
  };
  private formCommentTicket: FormData = new FormData();
  public comment = '';

  constructor(
    public router: Router,
    public global: GlobalService,
    private ticketService: TicketService,
    private causalService: CausalService,
    private subcausalService: SubcausalService,
    private motivoService: MotivoService,
    private stateService: StateService,
    private activatedRoute: ActivatedRoute,
    private filtrodescarga: DescargaFilter,
    private priorityService: PriorityService,
    private dataSharingService: DataSharingService,
    public datepipe: DatePipe,
    private modalService: NgbModal
  ) { }

  ngOnInit() {


    

    
    
    this.listaFiltros = [
      { id: 1, name: 'Mis tickets' },
      //{ id: 2, name: 'Con novedad' },
      { id: 3, name: 'Asignado a otros' },
      { id: 4, name: 'Cerrados' },
      { id: 5, name: 'Involucrados' },
      { id: 6, name: 'Abiertos' },
      { id: 7, name: 'Gestionados' },
      { id: 8, name: 'Vencidos' },
      { id: 9, name: 'En Validación' },
      { id: 10, name: '> 12 dias' },
      { id: 11, name: 'En Vencidos > 12 dias' },
      
    ];

    const filtrosSeleccionados = this.dataSharingService.getFiltrosSeleccionados();
    //console.log('Filtros seleccionados:', filtrosSeleccionados);

    this.priorities = this.priorityService.getList();
    var bcookies=localStorage.getItem('buscaCookies');
    
    //console.log(bcookies);
    if (filtrosSeleccionados) {
      
      console.log('areas:', filtrosSeleccionados[0]);
      if (filtrosSeleccionados[1] == '12d') {
        var d = new Date();
        var dia = new Date(d.setDate(d.getDate()));
        var fecha = dia.setDate(dia.getDate() - 13);
        this.fcrhasta = this.datepipe.transform(fecha, 'yyyy-MM-dd');
        this.noCerrados = true;
        
      }else{
        this.selectFiltro = parseInt(filtrosSeleccionados[1]);
        this.noCerrados = false;
        
      }

      this.areas = filtrosSeleccionados[0];

      this.filtroColumnas();
      
    }
    else if(bcookies==null){
      this.selectFiltro= 0;
      this.pagina= 0;
      localStorage.setItem('buscaCookies', JSON.stringify(this.busquedafiltros));
      //this.noCerrados = false;
    }
    else{
      this.busquedafiltros=JSON.parse(bcookies);
      this.pagina= this.busquedafiltros.pagina;
      this.selectFiltro= this.busquedafiltros.filtro;
      this.columnas= this.busquedafiltros.columnas;
      if(JSON.parse(bcookies)['nocerrados']=='true'){
        this.noCerrados = true;
      }
      //console.log(JSON.parse(bcookies)['nocerrados']);
      //console.log(this.noCerrados);
      //this.noCerrados = false;
    }
    
    this.filtrar();
    
    //console.log("tickets ",this.tickets);
    
    this.causals = this.causalService.getList();
    this.subcausalService.getList().then((response: any) => {
      this.subcausals = response.data;
    });
    this.states = this.stateService.getList();
    this.motivos = this.motivoService.getList();

    this.states_check = this.stateService.getList();
    //remove from this.states with id = 0
    this.states_check = this.states_check.filter(state => state.id != 1);


  }

  pasarPagina() {
    this.pagina += 1;
    this.filtrar();
   
  }

  volverPagina() {
    this.pagina -= 1;
    this.filtrar();
    
  }

  downloadExcel() {
    
    this.ticketService.getListExcel(this.pagina, this.selectFiltro, this.global.USER_DATA.id, this.columnas, this.noCerrados, this.excel ).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
       
      }
    });
  }


  filtroreset(){
    this.pagina= 0;
    this.noCerrados = false;
    this.filtrar();
    
  }


  filtrar(){
    //this.pagina= 0;
    this.busquedafiltros.pagina= this.pagina;
    this.busquedafiltros.filtro= this.selectFiltro;
    this.busquedafiltros.columnas= this.columnas;
    if(this.noCerrados){
      this.busquedafiltros.nocerrados= this.noCerrados.toString();
    }else{
      this.busquedafiltros.nocerrados= '';
    }
    
    this.ticketService.getList(this.busquedafiltros.pagina, this.busquedafiltros.filtro,this.global.USER_DATA.id, this.busquedafiltros.columnas, this.busquedafiltros.nocerrados).then((response: any) => {
      this.tickets = response.data;

      this.ticketService.setTicketNums(response.ticketsNums);
      //console.log("tickets ",this.numsTickets);

      //add column this.ticket.dias_asginado = differenceInDays(new Date(), new Date(this.ticket.fecha_area_mod));
      this.tickets.forEach(ticket => {
        if (ticket.estado.id !== 4) {
          // Calcular los días desde la fecha de modificación del área
          ticket.dias_asignado = differenceInDays(new Date(), new Date(ticket.fecha_area_mod));
        } else {
          // Si el estado es 4, asignar 0
          ticket.dias_asignado = 0;
        }
      });

      //console.log("tickets"+JSON.stringify(this.tickets[0]));
    });
    console.log(this.busquedafiltros);

    localStorage.setItem('buscaCookies', JSON.stringify(this.busquedafiltros));
  }

  filtroColumnas(){
    this.listaColumnas = { 
      ticket: this.ticketId ,
      clienteNom: this.nombreCliente ,
      clienteAp: this.apellidoCliente ,
      clienteDoc: this.docCliente ,
      asuntoCaus: this.asuntoCausal ,
      asuntoSubc: this.asuntoSubcausal ,
      asuntoMot: this.asuntoMotivo ,
      responsableNom: this.responsableNombre ,
      responsableAp: this.responsableApellido ,
      implic: this.involucrados ,
      areasAsig: this.areas ,
      estado: this.estado ,
      fechaDesde: this.fdesde ,
      fechaHasta: this.fhasta,
      fechacrDesde: this.fcrdesde ,
      fechacrHasta: this.fcrhasta,
      numero_llamadas: this.numero_llamadas,
      dias_desde_creacion: this.dias_desde_creacion
    };
    this.pagina= 0;
    this.columnas= JSON.stringify(this.listaColumnas);

    this.filtrar();

  }

  camposreset(){
    //this.columnas='';
    this.noCerrados = false;
    //console.log(this.columnas);

  }

  filtroReporte(){
    this.fechasReporte = {
      fechaDesde: this.repdesde ,
      fechaHasta: this.rephasta
    };
    this.fechasr = JSON.stringify(this.fechasReporte);
    //console.log(this.fechasr);
  }

  modalReporte(modal){
    this.modalService.open(modal, { size: 'lg' });
  }

  descargarReporte(){
    console.log("hola reporte");
    this.ticketService.getReporte(this.fechasr).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
        console.log("descarga");
       
      }
    });
  }
  
  toggleAll() {
    for (let ticket of this.tickets) {
      ticket.selected = this.selectAll;
    }
    this.selectAll = this.tickets.every(t => t.selected);
  }

  // changeStatus() {
  //   const selectedTickets = this.tickets.filter(ticket => ticket.selected);
  //   let num_tickets = selectedTickets.length;
  //   if (!selectedTickets.length) {
  //     alert('Please select at least one ticket');
  //     return;
  //   }
  //   console.log('Selected tickets:', selectedTickets);
  //   const updates = selectedTickets.map(ticket => {
  //     const formDataTicket = {
  //       subcausal_id: ticket.subcausal_id,  // Asume que estas propiedades existen en el objeto ticket
  //       radicado: ticket.radicado,
  //       subradicado: ticket.subradicado,
  //       areas_asignadas: JSON.stringify(ticket.areas_asignadas),
  //       prioridad: ticket.prioridad,
  //       estado_id: this.newStatus,  // El nuevo estado viene de la selección del usuario
  //       fecha_vencimiento: ticket.fecha_vencimiento,
  //       implicados: JSON.stringify(ticket.implicados),
  //       adjuntos: JSON.stringify(ticket.adjuntos),
  //       adjuntoscom: JSON.stringify(ticket.comments),
  //       dias_coljuegos: ticket.dias_coljuegos,
  //       dias_h: ticket.dias_habiles || 0,
  //     };
  
  //     return this.ticketService.update(ticket.id, formDataTicket).then((response: any) => {
  //       return {ticketId: ticket.id, status: response.status, message: response.message};
  //     });
  //   });
  
  //   Promise.all(updates).then(results => {
  //     results.forEach((result: any) => {
  //       if (result.status === 'success') {
  //         console.log(`Ticket ${result.ticketId} updated successfully: ${result.message}`);
  //       } else {
  //         console.error(`Failed to update ticket ${result.ticketId}: ${result.message}`);
  //       }
  //     });
  //     console.log('All updates completed ',num_tickets);
  //     // Optionally, refresh the list or redirect the user
  //     const temp=this.modalService.open(NgbdModalAlert);
  //     temp.componentInstance.text=" Se actualizaron "+num_tickets+" tickets correctamente.";
  //     this.filtrar();

  //   });
  // }
  changeStatus() {
    const selectedTickets = this.tickets.filter(ticket => ticket.selected);
    let num_tickets = selectedTickets.length;
    if (!selectedTickets.length) {
      alert('Please select at least one ticket');
      return;
    }
    console.log('Selected tickets:', selectedTickets);
  
    // Añadir el comentario a cada ticket seleccionado
    selectedTickets.forEach(ticket => {
      this.formCommentTicket = new FormData();
      this.formCommentTicket.append('contenido', this.comment);
      this.formCommentTicket.append('ticket_id', ticket.id);
  
      this.ticketService.sendComment(this.formCommentTicket).then((response: any) => {
        if (response.status === 'success') {
          console.log(`Comment added to ticket ${ticket.id}: ${response.message}`);
        } else {
          console.error(`Failed to add comment to ticket ${ticket.id}: ${response.message}`);
        }

      });
    });

  
    const updates = selectedTickets.map(ticket => {
      const formDataTicket = {
        subcausal_id: ticket.subcausal_id,  // Asume que estas propiedades existen en el objeto ticket
        radicado: ticket.radicado,
        subradicado: ticket.subradicado,
        areas_asignadas: JSON.stringify(ticket.areas_asignadas),
        prioridad: ticket.prioridad,
        estado_id: this.newStatus,  // El nuevo estado viene de la selección del usuario
        fecha_vencimiento: ticket.fecha_vencimiento,
        implicados: JSON.stringify(ticket.implicados),
        adjuntos: JSON.stringify(ticket.adjuntos),
        adjuntoscom: JSON.stringify(ticket.comments),
        dias_coljuegos: ticket.dias_coljuegos,
        dias_h: ticket.dias_habiles || 0,
      };
  
      return this.ticketService.update(ticket.id, formDataTicket).then((response: any) => {
        return { ticketId: ticket.id, status: response.status, message: response.message };
      });
    });
  
    Promise.all(updates).then(results => {
      results.forEach((result: any) => {
        if (result.status === 'success') {
          console.log(`Ticket ${result.ticketId} updated successfully: ${result.message}`);
        } else {
          console.error(`Failed to update ticket ${result.ticketId}: ${result.message}`);
        }
      });
      console.log('All updates completed', num_tickets);
      // Optionally, refresh the list or redirect the user
      const temp = this.modalService.open(NgbdModalAlert);
      temp.componentInstance.text = "Se actualizaron " + num_tickets + " tickets correctamente.";
      this.filtrar();
    });
  }
  

  anySelected(): boolean {
    // y this.newStatus tiene un valor
    return this.tickets.some(ticket => ticket.selected) && this.newStatus;
  }



}
